export const apiBaseUrl = (process.env.NODE_ENV === 'development' ? 'https://yagrill.kz' : '') + '/api';

export const apiAdminUrl = apiBaseUrl + '/admin';

export const interceptorsConfig = (config) => {
    const token = localStorage.getItem('token');
    const lang = localStorage.getItem('i18nextLng');

    config.headers.Authorization = token ? `Bearer ${token}` : null;
    config.headers.language = lang ? lang : 'en';

    return config;
}