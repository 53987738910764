import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {
            lang: 'Язык',
            phone_call: '',
            stock: 'Акции',
            tel: 'Тел',
            email: 'Почта',
            address: 'Адрес',
            currency: 'тг',
            to_cart: "В корзину",
            availability: "Доступность",
            in_stock: "В наличие",
            not_available: "Нет в наличие",
            to_cart_for: "В корзину за",
            phone: 'Номер телефон',
            name: 'Имя',
            bonuses: 'Бонусы',
            wishlist: 'Избранное',
            my_orders: 'Мои заказы',
            history: 'История',
            view_all: 'Смотреть все',
            welcome_profile: 'Привет,',
            dashboard: 'Профиль',
            all_addresses: 'Мои адреса',
            profile: 'Персональные данные',
            birthday: 'День рождения',
            gender: 'Пол',
            edit: 'Редактировать',
            delete_profile: 'Удалить профиль',
            active_orders: 'Активные',
            order: 'Заказ',
            add_address: 'Добавить адрес',
            delivery_payment: 'Доставка и оплата',
            order_date: 'Дата',
            delivery_variant: 'Вариант доставки',
            message: 'Комментарий к заказу',
            order_list: 'Состав заказа',
            total: 'Всего',
            discount: 'Скидка',
            login: 'Войти',
            profile_edit: 'Редактирование профиля',
            bonus_history: 'История бонусов',
            logout: 'Выйти',
            save: 'Сохранить',
            repeat_order: 'Повторить заказ',
            code: 'Код подтверждения',
            to_order: 'Перейти к заказу',
            bonus_gained: 'Оплачено бонусами',
            sub_total: 'Промежуточный итог',
            discount_price: 'Скидка',
            delivery_price: 'Цена доставки',
            delivery_address: 'Адрес доставки',
            choose_another: 'Выбрать другой',
            entrance: 'Подъезд',
            floor: 'Этаж',
            apartment: 'Квартира',
            code_domofon: 'Код домофона',
            female: 'жен',
            male: 'муж',
            success_update_profile: 'Данные профиля успешно обновлены',
            pick_up_point: 'Пункт выдачи',
            payment_variant: "Способ оплаты",
            your_order: "Ваш заказ",
            spend_bonuses: "Потратить бонусы",
            bonuses_accumulated: "бонусов накоплено",
            used_bonuses: "Использовано бонусов",
            delivery: "Доставка",
            checkout_order: "Оформить заказ",
            user_data: "Данные пользователя",
            new_address: "Укажите адрес доставки",
            success_add_address: "Адрес добавлен",
            cart: "Корзина",
            delete_profile_content: 'Вы уверены, что хотите удалить свой профиль?',
            cancel: 'Отмена',
            delete: 'Удалить',
            select: 'Выбрать',
            pick_up_selected: 'Пункт выдачи выбран',
            address_success_update: 'Адрес изменен',
            product_to_cart_success: 'Товары успешно добавлены в корзину!',
            cabinet: 'Кабинет',
            street: 'Улица',
            apply: 'Применить',
            app_title: 'В приложении удобнее',
            app_content: 'Скачивайте наше приложение в App Store и Google play',
            menu: 'Меню',
            contacts: 'Контакты',
            checkout_ordering: 'Оформление заказа',
            get_bonuses: 'Будет начислено',
            get_bonuses_content: 'Бонусы будут начислены после получения товара',
            free_delivery_amount: 'До бесплатной доставки осталось',
            no_active_orders: 'У вас нет активных заказов',
            empty_data: 'Данных не обнаружено',
            entry: "Вход",
            login_content: "Мы отправим Вам смс с кодом подтверждения на телефон",
            get_code: "Получить код",
            promocode: "Промокод",
            promo_discount_content: "Скидка по промокоду",
            promo_product_content: "Товар по промокоду",
            promo_delivery_content: "Доставка по промокоду",
            free: "Бесплатно",
            error_title: "Не удалось найти страницу",
            error_content: "Страница, которую вы ищете, не может быть найдена. Ссылка на этот адрес может быть устаревшей или мы могли переместить ее с тех пор, как вы последний раз добавляли ее в закладки.",
            error_button: "Вернуться на главную",
            footer_title: 'EasyFood',
            footer_bottom_title: "EasyFood.kz",
            thanks: 'Спасибо',
            not_accent_orders: "Временно не принимаем заказы на сайте и в мобильном приложений, приносим свои извинения",
            edit_profile: "Редактировать профиль",
            menu_profile: 'Профиль',
            select_pickup: "Выберите адрес самовывоза",
            payment: "Оплата",
            for_present: "Подарок",
            delivery_select: "Укажите ваш адрес",
            select_delivery_point: "Выберите адрес доставки",
            attach_address: "Прикрепить новый адрес",
            select_day_time: "Выбрать день и время",
            time: "Время",
            time_unavailable: "Простите, на это время заказ недоступен.",
            time_delivery: "Время доставки",
            order_comment: "Комментарий к заказу",
            your_comment: "Ваш комментарий",
            select_payment: "Выберите способ оплаты",
            bonuses_accumulated: "бонусов накоплено",
            not_call: "Не перезванивать",
            empty_cart: "Здесь пока ничего нет",
            to_main: "На главную",
            clear_basket: "Очистить корзину",
            near_future: "В ближайшее время",
            add_taste: "Добавить по вкусу",
            my_data: "Мои данные",
            notifications: "Уведомления",
            invite_friend: "Пригласи друга",
            support: "Поддержка",
            about_us: "О нас",
            faq: "Часто задаваемые вопросы",
            settings: "Настройки",
            select_city: "Выберите город",
            clear_basket_basket: "Вы уверены что хотите очистить корзину?",
            clear: "Очистить",
            apartment_short: "Кв.",
            select_lang: "Выберите язык",
            delivery_by: "Доставка по",
            qr_code: "QR-код",
            login_profile: "Войдите в профиль",
            login_profile_content: "Введите номер телефона, чтобы копить бонусы, сохранить адрес доставки и историю заказов",
            exit_profile:"Выйти из аккаунта",
            promo_discount: "Скидка по промокоду",
            promo_delivery: "Доставка по промокоду",
            something_else: "Что ещё пригодится",
            sure: "Понятно",
            profile_bonus_content: 'Дорогие гурманы, мы заботимся не только о вашем вкусе, но и о вашем удовольствии от покупок! Представляем вам нашу функцию "Бонусы" — специальное предложение, которое делает ваш опыт заказа еды еще более приятным и выгодным.',
            profile_bonus_title: 'Приятные бонусы: удовольствие в каждом заказе!',
            order_success: "Заказ успешно оформлен",
            to_profuct: "Перейти к товару",
            to_category: "Перейти к акции",
            additional: "Добавка",
            address_no_zone: "Адрес вне зоны доставки",
            delete_address_content: "Вы уверены, что хотите удалить адрес?",
            product_add_cart: "Товар добавлен в корзину",
            present_title: "Ура!",
            get_present: "Вы получили подарок",
            to_present: "До подарка",
            present_more: "Еще",
            present_from_price: "Подарок при заказе от",
            delivery_title: "Ура!",
            delivery_free: "У Вас бесплатная доставка!",
            to_free_delivery: "До бесплатной доставки",
            delivery_more: "Еще",
            delivery_from: "Доставка при заказе до",
            delivery_to_free: "Доставка при заказе от",
            delivery_time: "Время ожидания",
            delivery_time_value: "~ 45 мин",
            promocode_success: "Промокод успешно применен",
            recomended_products: "Часто заказывают",
            password: "Пароль",
            registration: "Зарегистрироваться",
            registration_content: "Для регистрации необходимо ввести почту и пароль",
            password_confirmation: "Подтвердите пароль",
            recovery_password: "Восстановление пароля",
            forgot_password_content: "Для восстановления пароля введи почту куда будет отправлен код",
            send_code: "Отправить код",
            enter_code: "Введите код",
            update_password: "Обновить пароль",
            sections: "Разделы",
            confirm_password: "Подтвердите пароль",
            privacy_police: "Политика конфиденциальности",
            public_offer: "Публичная оферта",
            application_banner_title: "ПОПРОБУЙТЕ НАШЕ ПРИЛОЖЕНИЕ",
            application_banner_content: "Всегда на ногах? Перейдите на приложение!",
            application_modal_title: "Скачивайте наше мобильное приложение!",
            application_modal_content: "Будьте первым, кто получит промокоды, скидки и специальные акции!",
            application_modal_content: "Будьте первым, кто получит промокоды, скидки и специальные акции!",
            download_app: "Установить приложение",
            on_wait: "Ожидается"
        }
        this._lang = localStorage.getItem('i18nextLng')
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._registerByPhone = false
        this._activeMenuItem = 'menu'
        this._promocodes = null
        this._city = null
        this._allCities = []
        this._banner = null
        this._bonusEnabled = null
        makeAutoObservable(this)
    }

    setBonusEnabled(bonusEnabled) {
        this._bonusEnabled = bonusEnabled
    }

    setBanner(banner) {
        this._banner = banner
    }

    setAllCity(cities) {
        this._allCities = cities
    }

    setCity(city) {
        localStorage.setItem('city', JSON.stringify(city));
        this._city = city
    }

    setPromocodes(promocodes) {
        this._promocodes = promocodes
    }

    setRegisterByPhone(registerByPhone) {
        this._registerByPhone = registerByPhone
    }

    setActiveMenuItem(activeMenuItem) {
        this._activeMenuItem = activeMenuItem
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get city() {
        return JSON.parse(localStorage.getItem('city'));
    }

    get bonusEnabled() {
        return this._bonusEnabled;
    }

    get banner() {
        return this._banner;
    }

    get cityString() {
        return this._city
    }

    get allCities() {
        return this._allCities
    }

    get promocodes() {
        return this._promocodes
    }

    get activeMenuItem() {
        return this._activeMenuItem
    }

    get registerByPhone() {
        return this._registerByPhone
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}